import React from 'react';
import axios from 'axios';
import Navbar from '../Navbar';
import Footer from '../Footer';
import {
    WORDPRESS_URL,
    WP_CATEGORIES_SLUG,
    WP_POST_CATEGORIES_WITH_FEATUREDMEDIA
} from "../../utils/constants";
import Preloader from "../Preloader";
import renderHTML from "react-render-html";
import { Link, Redirect } from "react-router-dom";
import defaultBg from "../../images/category-clanek.jpg";
import kosodelnik from "../../images/kosodelnik.png";
import kosodelnikModry from "../../images/kosodelnik-modry.png";

class Trains extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            isLoading: true,
            actualitiesArray: [],
            index: 1,
            headerBgClass: "home",
            firstRectSrc: kosodelnikModry,
            secondRectSrc: kosodelnik, 
            nextHeaderBgIndex: 2,
            firstArticle: "",
            secondArticle: "hidden",
            error: {},
            redirectInternalError: false
        }

        this.bgToggle = this.bgToggle.bind(this);
    }

    bgToggle(number) {
        if (number === 1) {
          this.setState({
              firstRectSrc: kosodelnikModry,
              secondRectSrc: kosodelnik,
              headerBgClass: "home",
              nextHeaderBgIndex: 2,
              firstArticle: "",
              secondArticle: "hidden"
          });
        } else {
            this.setState({
                firstRectSrc: kosodelnik,
                secondRectSrc: kosodelnikModry,
                headerBgClass: "recultivation",
                nextHeaderBgIndex: 1,
                firstArticle: "hidden",
                secondArticle: ""
            });
        }
      }

    componentDidMount(){
        axios.get( WORDPRESS_URL + WP_CATEGORIES_SLUG + "vlecky")
        .then(response_slug => {
            if (response_slug !== undefined) {
                axios.get( WORDPRESS_URL + WP_POST_CATEGORIES_WITH_FEATUREDMEDIA + response_slug.data[0].id)
                .then(response_actualities => {
                    if (response_actualities !== undefined) {
                        const actualitiesArray = response_actualities.data.map(function (data, id){
                            let featuredMediaLinks = data._embedded['wp:featuredmedia'] ? data._embedded['wp:featuredmedia'] : null;
                            let featuredMediaLink = featuredMediaLinks ? `url("${featuredMediaLinks[0].source_url}")` : `url("${defaultBg}")`; //rest api na získání úvodního obrázku
                            return {
                                idx: id,
                                title: data.title.rendered,
                                text: data.content.rendered, 
                                shortText: data.excerpt.rendered,
                                slug: data.slug,
                                featuredMedia: featuredMediaLink
                            };
                        });
        
                        this.setState({
                            isLoading: false, 
                            actualitiesArray: actualitiesArray
                        }); 
                    }                    
                });
            }                                   
        })
        .catch( error => {
            console.log(error);  

            this.setState({ 
                error: error, 
                isLoading: false,
                redirectInternalError: true
            });       
        });

        this.interval = setInterval(() => this.bgToggle(this.state.nextHeaderBgIndex), 14000);
    }
  
    componentWillUnmount() {
      clearInterval(this.interval);
    }

    renderHeader = () => {
        return(
            <header className={"category-header " + this.state.headerBgClass}>
                <Navbar />
                
                <div className="sd-container">
                    <section id="sub-header">
                        <div className="row">
                            <div className="col-md-7 col-xl-8 ">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="sub-header-sub-caption text-uppercase">
                                SD - Kolejová doprava
                                </h6>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="sub-header-caption text-uppercase">
                                    Vlečky
                                </h6>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="sub-header-chevrons">
                                <img
                                    src={this.state.firstRectSrc}
                                    alt="chevron_white"
                                    className="first header-toogle-button"
                                    onClick={() => this.bgToggle(1)}
                                />
                                <img
                                    src={this.state.secondRectSrc}
                                    alt="chevron_sd"
                                    className="second header-toogle-button"
                                    onClick={() => this.bgToggle(2)}
                                />
                                </span>
                            </div>
                            </div>
                            <div className={"col-md-5 col-xl-4 " + this.state.firstArticle}>
                            <div className="d-flex justify-content-between align-items-center sd-mt-4">
                                <h6 className="sub-header-sub-caption text-uppercase sd-hidden-xs">
                                Drážní doprava
                                </h6>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="sub-header-text">
                                O zabezpečení všech přeprav na dráze celostátní a dráhách regionálních v rozsahu platné licence se stará Útvar externích přeprav. 
                        Ten také hospodaří s přiděleným počtem lokomotiv a organizuje jejich práci. 
                        Řízení dopravy na tratích SŽ, s.o. zajišťuje nepřetržitě obsazené dispečerské pracoviště.
                                </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                            <Link
                                to={"/clanek/drazni-doprava"}
                                className="who-we-are-more-btn-link"
                                >
                                <div className="sub-header-more-btn d-flex justify-content-between align-items-center">
                                <span className="btn-text text-white text-uppercase pl-4">
                                    Zjistit více{" "}
                                    <i className="fa fa-chevron-right ml-2"></i>
                                </span>
                                </div>
                                </Link>
                            </div>
                            </div>

                            <div className={"col-md-5 col-xl-4 " + this.state.secondArticle}>
                            <div className="d-flex justify-content-between align-items-center sd-mt-4">
                                <h6 className="sub-header-sub-caption text-uppercase sd-hidden-xs">
                                Vlečky
                                </h6>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="sub-header-text">
                                V rámci naší činnosti poskytujeme komplexní služby při provozu vleček na čtyřech lokalitách.
                                </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                            <Link
                                to={"/vlecky"}
                                className="who-we-are-more-btn-link"
                                >
                                <div className="sub-header-more-btn d-flex justify-content-between align-items-center">
                                <span className="btn-text text-white text-uppercase pl-4">
                                    Zjistit více{" "}
                                    <i className="fa fa-chevron-right ml-2"></i>
                                </span>
                                </div>
                                </Link>
                            </div> 
                            </div>
                        </div>
                    </section>
                </div>
            </header>
        );
    }

    renderMainContainer = () => {
        return(
            <section id="main-container">
                <div className="sd-container-aktuality category">
                    <div className="d-flex sd-container-aktuality-flex sd-container-category">
                        <div>
                        {this.renderArticles()} 
                        </div>                       
                    </div>
                </div>
            </section>
        );
    }

    renderSecondaryContainer = () => {
        return(
            <section id="secondary-container" className="sd-hidden-xs contact-secondary-container"></section>
        );
    }

    renderArticles = () => {
        const { actualitiesArray } = this.state;

        const actualities = actualitiesArray.map((actuality, index) =>
            <Link to={`clanek/${actuality.slug}`}>
                <div className="sd-category-article" key={index} style={{backgroundImage: actuality.featuredMedia }}>
                    <div className="sd-category-article-body">
                        <div className="sd-article-title text-uppercase">{ renderHTML(actuality.title) }</div>
                        <div className="sd-article-content">{ renderHTML(actuality.shortText) }</div>
                        
                        <div className="sd-article-link"> 
                            <span className="pull-right text-uppercase">
                                <b>Přečíst</b> článek
                                <i className="fa fa-chevron-right sd-text-primary ml-2"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </Link>
        );

        return actualities;
    }

    renderFooter = () => {
        return(
            <footer className="footer-contact-height">
                <div className="sd-container">
                    <section id="footer-content" className="footer-top-padding footer-top-padding-contact">
                        <Footer/>
                    </section>
                </div>
            </footer>           
        );
    }

    render(){
        const { isLoading, redirectInternalError, error } = this.state;

        return (            
            <section id="web">
                {isLoading ? (
                    <Preloader isLoaded={!isLoading} />
                ) : (redirectInternalError ? (
                    <Redirect to={{
                        pathname: "/500",
                        state: { error: error }
                      }} />
                ) : ( 
                    <div className="wrapper">
                        {this.renderHeader()}

                        {this.renderMainContainer()}

                        {this.renderSecondaryContainer()}

                        {this.renderFooter()}
                    </div>
                ))}
            </section>        
        )
    }
}

export default Trains;