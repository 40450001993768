import React from 'react';
import logoFooter from '../images/logo_footer.png';

class Footer extends React.Component{

    constructor(props){
        super(props);

        this.state = {
        }
    }

    render(){
        return (
            <div>
        <div className="row">
            <div className="col-md-12 d-flex footer-logo align-items-center">
                <img src={logoFooter} alt="logo_footer"/>
            </div>
        </div>

        <hr className="hr-white" />

        <div className="row footer-text">
            <div className="col-md-6">
                <div className="row mt-3 mb-3">
                    <div className="col-sm sd-hidden-xs"><a href="/kontakty" className="text-white">Kontaktujte nás</a></div>
                    <div className="col-sm sd-visible-xs font-weight-bold">Kontaktujte nás</div>
                </div>

                <div className="row">
                    <div className="col-md-4 sd-hidden-xs">Telefon</div>
                    <div className="col-md-8">+420 474 602 161</div>
                </div>

                <div className="row">
                    <div className="col-md-4 sd-hidden-xs">Email</div>
                    <div className="col-md-8">info@sd-kd.cz</div>
                </div>

                <div className="row">
                    <div className="col-md-4 sd-hidden-xs">Adresa</div>
                    <div className="col-md-8">Tušimice 7<br />Kadaň, 432 01</div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="row mt-3 mb-3">
                    <div className="col-sm sd-hidden-xs"><a href="/o-spolecnosti" className="text-white">O společnosti</a></div>
                    <div className="col-sm mt-3 sd-visible-xs font-weight-bold"><a href="/o-spolecnosti" className="text-white">O společnosti</a></div>
                </div>

                <div className="row">
                    <div className="col-md-7">
                        <p className="m-0 "><a href="/clanek/drazni-doprava" className="text-white">Drážní doprava</a></p>
                        <p className="m-0 "><a href="/vlecky" className="text-white">Vlečky</a></p>
                        <p className="m-0 "><a href="/clanek/zauhlovani-elektraren" className="text-white">Zauhlování elektráren</a></p>
                        <p className="m-0 "><a href="/specialni-sluzby" className="text-white">Speciální služby</a></p>
                        <p className="m-0 "> <a href="/clanek/katalog-techniky" className="text-white">Katalog techniky</a></p>
                    </div>

                    <div className="col-md-5">
                        <p className="m-0 "><a href="https://sdas.jobs.cz/o-spolecnosti/#sd-kolejova-doprava" target="_blank"  className="text-white">Práce u nás</a></p>
                        <p className="m-0 "><a href="/clanek/ochrana-osobnich-udaju" className="text-white">Ochrana osobních údajů</a></p>
                        <p className="m-0"><a href="/clanek/eticka-linka-oznamovatele" className="text-white">Etická linka oznamovatele</a></p>
                        <p className="m-0"><a href="/clanek/cookies" className="text-white">Cookies</a></p>
                        <p className="m-0 "><a href="/kontakty" className="text-white">Kontakt</a></p>
                    </div>
                </div>
            </div>
        </div>

        <div className="row mt-5 sd-hidden-xs">
            <div className="col-12 text-right">&copy; SD – Kolejová doprava, a. s.</div>
        </div>

        <div className="actual-xs-footer">
            <hr className="hr-white-footer" />
        <div className="row mt-5">
            <div className="col-12 text-center">&copy; SD – Kolejová doprava, a. s.</div>
        </div>
        </div>
        </div>
        )
    }
}

export default Footer;