import React from 'react';
import Homepage from './components/pages/Homepage';
import Contact from './components/pages/Contact';
import Detail from "./components/pages/Detail";
import Actualities from "./components/pages/Actualities";
import Trains from './components/pages/Trains';
import Services from './components/pages/Services';
import About from './components/pages/About';
import NotFound from './components/pages/NotFound';
import SearchResults from './components/pages/SearchResults';
import InternalServerError from './components/pages/InternalServerError';
import { BrowserRouter, Switch, Route } from "react-router-dom";

export const routeCodes = {
  HOME: '/',
  ABOUT: '/o-spolecnosti',  
  DETAILPOST: '/clanek/:slug',
  TATRA: '/autorizovany-servis-tatra',
  CONTACT: '/kontakty',
  ACTUALITIES: '/aktuality',
  TRAINS: '/vlecky',
  SERVICES: '/specialni-sluzby',
  SEARCHRESULTS: '/hledat/:search',
  };

class App extends React.Component {
	render() {
		return (
      <BrowserRouter basename="">
        <Switch>
          <Route path={ routeCodes.HOME } component={Homepage} exact />
          <Route path={ routeCodes.ABOUT } component={About} exact />
          <Route path={ routeCodes.CONTACT } component={Contact} exact />
          <Route path={ routeCodes.DETAILPOST } component={Detail} exact /> 
          <Route path={ routeCodes.ACTUALITIES } component={Actualities} exact /> 
          <Route path={ routeCodes.TRAINS } component={Trains} exact /> 
          <Route path={ routeCodes.SERVICES } component={Services} exact /> 
          <Route path={ routeCodes.SEARCHRESULTS } component={SearchResults} exact />
          <Route path="/500" component={InternalServerError} />
          <Route path="*" component={NotFound} />        
        </Switch>
      </BrowserRouter>
		);
	}
}

export default App;

