import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';

class Navbar extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            menu: false,
            showInput: false,
            search: "",
          };
          this.toggleMenu = this.toggleMenu.bind(this);
          this.toggleInput = this.toggleInput.bind(this);
          this.handleChange = this.handleChange.bind(this);
          this.onClick = this.onClick.bind(this);
        //   this.onKeyPress = this.onKeyPress.bind(this);
        }
      
        toggleMenu(){
          this.setState({ menu: !this.state.menu })
        }
        toggleInput(){
            this.setState({ showInput: !this.state.showInput })
        }

        handleChange(event) {
            this.setState({
                [event.target.name]: event.target.value
            });
        }

        onKeyPress = (e) => {
            if(e.which === 13) {
                let s = this.state.search;
                document.location = "/hledat/"+s;
            }
          }

          onClick(){
            if(this.state.search){
                let s = this.state.search;
                document.location = "/hledat/"+s;
            }
          }
    

    render(){
        const show = (this.state.menu) ? "show" : "" ;
        const showInput = (this.state.showInput) ? "show" : "hidden" ;
        return (
            <nav className="sd-navbar navbar navbar-light navbar-expand-lg navigation-clean">
            <div className="sd-navbar-brand">
                <Link to={"/"}>
                <img src={logo} alt="logo" />
                </Link>
            </div>

            <div className={"menu-collapse " + show}>
            <button type="button" className="navbar-toggler" data-target="#navcol-1" data-toggle="collapse" onClick={ this.toggleMenu }>
                <span className="sr-only">Toggle navigation</span>
                {show === "show" ? (
                    <span className="fa fa-times text-white fa-2x"></span>
                ) : (
                    <span className="fa fa-bars text-white fa-2x"></span>
                )
                }
            </button>

            <div id="navcol-1" className={"collapse navbar-collapse " + show}>
                <ul className="nav navbar-nav ml-auto">
                    
                    <li className="nav-item" role="presentation">
                        <a href="https://www.sd-kd.cz/dokumenty/ROS_SDKD.xlsx" download="ROS_SDKD.xlsx"  target="_blank" className="nav-link active text-uppercase">Rejstřík doplňkových osvědčení</a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a href="/clanek/externi-cenik" className="nav-link active text-uppercase">Externí ceník výkonů</a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a href="https://www.sd-kd.cz/dokumenty/pravidla.pdf" className="nav-link active text-uppercase" target="_blank">Pravidla</a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a href="/clanek/katalog-techniky" className="nav-link active text-uppercase">Katalog techniky</a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a href="https://sdas.jobs.cz/o-spolecnosti/#sd-kolejova-doprava" className="nav-link active text-uppercase"  target="_blank">Práce u nás</a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a href="/kontakty" className="nav-link active text-uppercase">Kontakty</a>
                    </li>

                    <li className={"li-search-button-responsive-menu nav-item collapse " + show} role="presentation">
                        <div className={"form-group collapse search-input show"}>
                            <input type="text" name="search" value={this.state.search} onKeyPress={this.onKeyPress} onChange={this.handleChange} className="form-control contact-form-input search-input" id="search"/>
                        </div>
                        <button type="button" className="btn sd-btn-square sd-btn-primary search-button-responsive-menu" onClick={ this.onClick }>
                            <i className="fa fa-search"></i>
                        </button>
                    </li>
                </ul>
            </div>
            
            <div className="sd-search-button">
                <button type="button" className="btn sd-btn-square sd-btn-primary"  onClick={ this.toggleInput }>
                    <i className="fa fa-search"></i>
                </button>
                <div className={"col-xs-hidden form-group collapse search-input " + showInput}>
                    <input type="text" name="search" value={this.state.search} onKeyPress={this.onKeyPress} onChange={this.handleChange} className="form-control contact-form-input search-input" id="search"/>
                </div>
            </div>
            </div>
        </nav>
        )
    }
}

export default Navbar;